.hero-full-height {
  height: 100svh;
  background-image: url('../../../assets/Images/hero-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}

.hero-flex {
  display: flex;
}

.hero-title {
  font-size: 50px;
  white-space: nowrap;
  border-right: 3px solid;
  width: auto;
  overflow: hidden;
  height: 60px;
  /* Keeps the height fixed to avoid flickering */
}

.hero-title-mk {
  font-size: 50px;
  white-space: nowrap;
  border-right: 3px solid;
  width: auto;
  overflow: hidden;
  height: 60px;
  /* Keeps the height fixed to avoid flickering */
}

/* Typing Cursor Blink Animation */
@keyframes blinkCursor {
  from {
    border-right-color: rgba(0, 0, 0, 0.75);
  }

  to {
    border-right-color: transparent;
  }
}

.hero-title {
  animation: blinkCursor 0.7s step-end infinite;
}

.hero-description {
  width: 50%;
  color: #929292;
}

/* Hide description for small screens (max-width: 450px) */
@media (max-width: 450px) {
  .hero-full-height {
    background-image: url('../../../assets/Images/hero-bg-small.png');
    padding: 0 20px;
  }

  .hero-title {
    font-size: 30px;
    height: 35px;
  }

  .hero-title-mk {
    font-size: 20px;
    height: 25px;
  }

  .hero-description {
    display: none;
    /* Hide description on small screens */
  }
}

/* Hide description for medium screens (451px to 1024px) */
@media (min-width: 451px) and (max-width: 1024px) {
  .hero-full-height {
    background-image: url('../../../assets/Images/hero-bg-small.png');
    padding: 0 15px;
  }

  .hero-title {
    font-size: 50px;
    height: 60px;
  }

  .hero-title-mk {
    font-size: 40px;
    height: 50px;
  }

  .hero-description {
    display: none;
    /* Hide description on medium screens */
  }
}

/* Larger screens */
@media (min-width: 1025px) {
  .hero-title {
    font-size: 50px;
    height: 60px;
  }

  .hero-title-mk {
    font-size: 50px;
    height: 60px;
  }

  .hero-description {
    display: block;
    /* Ensure description is visible on larger screens */
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(0.8);
    /* Start small */
    opacity: 0;
    /* Start invisible */
  }

  100% {
    transform: scale(1);
    /* End with normal size */
    opacity: 1;
    /* Fully visible */
  }
}

.logo-animation {
  animation: scaleUp 1s ease-in-out;
  /* 2 seconds duration for smooth transition */
  height: 105.88px;
  width: 300px;
}