.header-title {
  font-size: 30px;
}

.header-box {
  display: flex;
  height: 70px;
  width: 70px;
  justify-content: center;
  align-items: center;
}

.header-container {
  display: flex;
  gap: 15px;
}
