/* Container for the gallery section */
.gallery-screen-container {
  height: 100svh;
  text-align: center;
  background-image: url('../../assets/Images/gallery-screen-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto; /* Allow vertical scrolling for content overflow */
  justify-content: space-between;
}

/* Content and header styling for the gallery */
.gallery-screen-content {
  display: flex;
  flex-direction: column; /* Stack header content vertically on smaller screens */
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 100px;
  padding-bottom: 50px;
}

/* Wrapper for the gallery slider */
.gallery-screen-wrapper {
  position: relative;
  width: 70%;
  margin: 0 auto;
  padding-bottom: 70px;
  transition: width 0.3s ease-in-out;
}

/* Main gallery slider */
.gallery-main-images {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 400px;
}

/* Center image (main image) */
.gallery-center-image {
  width: 60%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin: 0 20px;
  transition: transform 0.3s ease;
}

.gallery-center-image:hover {
  transform: scale(1.05); /* Hover effect */
}

/* Side images */
.gallery-side-image {
  width: 20%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
  border-radius: 10px;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.gallery-side-image:hover {
  transform: scale(1.05);
  opacity: 0.7;
}

/* Arrow buttons */
.gallery-arrow {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  font-size: 24px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: background 0.3s ease;
  z-index: 100;
  width: 50px;
}

.left-arrow {
  left: 30px; /* Positioned slightly more visible */
}

.right-arrow {
  right: 30px;
}

.gallery-arrow:hover {
  background: rgba(0, 0, 0, 0.8);
}

/* Thumbnails container */
.gallery-thumbnails {
  display: flex;
  justify-content: center;
  overflow-x: auto; /* Make the container scrollable */
  margin-top: 20px;
  padding-bottom: 10px;
  max-width: 100%;
  gap: 10px;
  scrollbar-width: none; /* Hide default scrollbar on Firefox */
}

.gallery-thumbnails::-webkit-scrollbar {
  display: none; /* Hide scrollbar on webkit browsers */
}

/* Thumbnails */
.gallery-thumbnail {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 5px;
  opacity: 0.5;
  transition: opacity 0.3s ease, transform 0.3s ease;
  cursor: pointer;
}

.gallery-thumbnail.active {
  opacity: 1;
  transform: scale(1.2);
}

.expand-button {
  position: absolute; 
  bottom: 10px; 
  right: -20px; 
  width: 50%; 
  border: 0;
  background-color: transparent;
}

/* Expand button hover effect */
.expand-button:hover {
  opacity: 0.7;
}

/* Media queries */

/* Medium screens (1024px and below) */
@media (max-width: 1024px) {
  .gallery-screen-wrapper {
    width: 85%;
  }

  .gallery-main-images {
    height: 300px;
  }

  .gallery-thumbnail {
    width: 50px;
    height: 50px;
  }

  .gallery-arrow {
    width: 40px;
  }

  .gallery-screen-content {
    padding-left: 40px;
    padding-right: 40px;
  }

  .left-arrow, .right-arrow {
    font-size: 20px;
    padding: 8px;
  }
}

/* Small screens (768px and below) */
@media (max-width: 768px) {
  .gallery-screen-container {
    height: 100vh;
  }

  .gallery-screen-wrapper {
    width: 100%;
    padding: 2rem 0;
  }

  .expand-button {
    position: absolute; 
    bottom: 10px; 
    right: 0px; 
    width: 50%; 
    border: 0;
    background-color: transparent;
  }

  .gallery-main-images {
    height: 250px;
  }

  .gallery-thumbnail {
    width: 40px;
    height: 40px;
  }

  .gallery-arrow {
    font-size: 18px;
    width: 40px;
    height: 40px;
  }

  .gallery-screen-content {
    padding-left: 30px;
    padding-right: 30px;
  }
}

/* Extra small screens (450px and below) */
@media (max-width: 450px) {
  .expand-button {
    position: absolute; 
    bottom: 10px; 
    right: 5px; 
    width: 50%; 
    border: 0;
    background-color: transparent;
  }

  .gallery-screen-wrapper {
    width: 100%;
  }

  .gallery-main-images {
    height: 200px;
  }

  .gallery-thumbnail {
    width: 35px;
    height: 35px;
  }

  .gallery-arrow {
    font-size: 16px;
    width: 40px;
    height: 40px;
  }

  .gallery-screen-container {
    background-image: url('../../assets/Images/gallery-screen-small-bg.png');
    padding: 0;
  }

  .gallery-screen-content {
    padding-left: 20px;
    padding-right: 20px;
  }

  .left-arrow, .right-arrow {
    font-size: 16px;
    padding: 6px;
  }

  .gallery-center-image {
    min-width: 240px; /* Further reduce the width for very small screens */
    height: 200px;
  }
}

/* Styles for modal */
.gallery-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
}

.gallery-modal-content {
  position: relative;
  width: 90%;
  max-width: 90%;
  height: auto;
  max-height: 90%;
}

.gallery-modal img, .gallery-modal video {
  width: auto;
  height: 90%;
}

.gallery-close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 30px;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
}

.gallery-modal img {
  object-fit: cover;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.close-modal {
  position: absolute;
  top: 20px;
  right: 40px;
  color: white;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
  z-index: 1001;
}

.modal-content {
  max-width: 80%;
  max-height: 80%;
  margin: auto;
}