/* Navbar container styles */
.navbar-fixed {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
}

.navbar-transition {
  transition: transform 0.3s ease-in-out;
}

.navbar-b0 {
  border: 0px;
  background-color: transparent;
  padding: 0px;
}

.navbar-black {
  font-size: 20px;
  border: 0px;
  background-color: transparent;
  padding: 0px;
  color: black;
}

.navbar-visible {
  transform: translateY(0);
}

.navbar-hidden {
  transform: translateY(-110%);
}

/* Navbar content container */
.navbar-container {
  display: flex;
  justify-content: flex-end;
  /* align-items: baseline; */
  margin-right: 32px;
  font-weight: 500;
}

/* Links section */
.navbar-links {
  display: flex;
  align-items: center;
  gap: 34px;
  color: white;
  padding-left: 65px;
  padding-right: 65px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  max-height: none;
  height: 45px;
}

/* Contact section */
.navbar-contact {
  display: flex;
  gap: 10px;
  flex-direction: column;
  background-color: white;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 14px;
  padding-right: 14px;
  margin-left: 20px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  border: 0;
}

/* Individual link styles */
.navbar-link {
  text-decoration: none;
  color: inherit;
}

.navbar-link:hover {
  text-decoration: underline;
}

/* Icons container */
.navbar-icons-container {
  display: none;
  flex-direction: row;
  /* align-items: center; */
  margin-right: 20px;
}

/* Burger menu and language icon */
.navbar-burger-menu,
.navbar-language-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  /* height: 50px; */
  /* padding-top: 12px;
  padding-bottom: 12px; */
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.navbar-language-menu {
  display: flex;
  gap: 10px;
  flex-direction: column;
  background-color: white;
  /* White background for the language icon */
  margin-right: 10px;
  /* Space between the language icon and the burger menu */
  border: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.navbar-burger-menu {
  background-color: #da1820;
  /* Red background for the burger menu */
  height: 45px;
}

/* Responsive layout for small screens */
@media (max-width: 768px) {
  .navbar-container {
    display: none;
  }

  .navbar-icons-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .navbar-fixed {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
  }
}

/* Burger menu dropdown styles */
.navbar-burger-dropdown {
  position: absolute;
  top: 0;
  /* Remove any top space */
  right: 0;
  left: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  z-index: 999;
  animation: slideDown 0.3s ease-out forwards;
}

.navbar-burger-dropdown.closing {
  animation: slideUp 0.3s ease-out forwards;
}

.navbar-burger-link {
  width: 100%;
  text-align: center;
  padding: 15px 0;
  color: #333;
  text-decoration: none;
  font-weight: bold;
}

.navbar-burger-link:hover {
  background-color: #f1f1f1;
}

/* Separator line */
hr {
  width: 90%;
  margin: 0;
  border: 0;
  height: 1px;
  background-color: #ddd;
}

/* Slide down animation */
@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Slide up animation */
@keyframes slideUp {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}