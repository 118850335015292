/* Larger screens */
.contact-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../assets/Images/contact-bg.png");
  /* Add your background image path */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}

.contact-height-width {
  height: 100%;
  width: 100%;
}

.contact-p40 {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 40px;
}

.contact-p40-2 {
  padding-left: 40px;
  padding-right: 40px;
}

.contact-button-layout {
  display: flex;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
}

.contact-btn-inner {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.contact-fs18 {
  font-size: 18px;
  padding: 0px;
}

.contact-fs35 {
  font-size: 35px;
  padding: 0px;
}

.contact-btn-width {
  width: 100%;
  margin: 0px;
}

/* Map */
.map-container {
  height: 500px;
  /* Fixed height for large screens */
  width: 500px;
  /* Width of the map */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

/* Contact Form */
.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #dedede;
  height: 570px;
  /* Form height is larger than the map and empty div */
  max-width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.contact-form h2,
.contact-form p {
  margin: 0;
  padding: 10px 0;
}

.contact-form input,
.contact-form textarea {
  border: none;
  border-bottom: 1px solid #000;
  width: 100%;
  padding: 10px 0;
  margin: 10px 0;
  background-color: transparent;
}

.contact-form input:focus {
  outline: 0;
  border-bottom: 2px solid #000;
  width: 100%;
  padding: 10px 0;
  margin: 10px 0;
}

.contact-form textarea:focus {
  outline: 0;
  border-bottom: 2px solid #000;
  width: 100%;
  padding: 10px 0;
  margin: 10px 0;
}

.contact-form button {
  margin-top: 20px;
  padding: 10px 20px;
  cursor: pointer;
}

/* Empty Div */
.empty-div {
  width: 70px;
  /* 1/4th the width of the map (500px / 4) */
  height: 500px;
  /* Same height as the map */
  background-color: #000;
  /* Black background */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.contact-button {
  width: "100%";
  margin: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 15px;
  padding-left: 15px;
  border: 0;
  font-size: 18px;
  background: #da1820;
  color: white;
}

/* Responsive Styling */
@media (max-width: 1024px) {
  .contact-section {
    padding-right: 70px;
    padding-left: 70px;
  }

  .map-container {
    height: 400px;
    width: 100%;
  }

  .empty-div {
    height: 400px;
    max-width: 50px;
  }

  /* Slightly larger form height for medium screens */
  .contact-form {
    height: 450px;
    width: 100%;
  }

  .empty-div {
    width: 25%;
    /* 1/4th the width of the map on medium screens */
  }
}

/* Smaller Screens */
@media (max-width: 768px) {
  .contact-form {
    max-width: 300px;
    /* Make map and form the same width */
    height: auto;
    /* Allow height to adjust dynamically */
  }

  .map-container {
    display: flex;
    max-width: 300px;
    /* Make map and form the same width */
    max-height: 300px;
    /* Allow height to adjust dynamically */
  }

  .empty-div {
    max-height: 300px;
    max-width: 50px;
  }

  .map-container,
  .contact-form {
    width: 100%;
    /* Ensure both the map and the form take up the same full width */
  }
}

@media (max-width: 650px) {
  .contact-section {
    display: flex;
    flex-direction: column-reverse;
    padding-right: 20px;
    padding-left: 20px;
  }

  .contact-form {
    min-width: 330px;
    /* Make map and form the same width */
    height: auto;
    /* Allow height to adjust dynamically */
  }

  .map-container {
    display: flex;
    width: 300px;
    /* Make map and form the same width */
    height: 400px;
    /* Allow height to adjust dynamically */
  }

  .empty-div {
    display: flex;
    min-width: 300px;
    height: 30px;
    box-shadow: 0 0px 6px rgba(0, 0, 0, 0.3);
  }

  .map-container,
  .contact-form {
    width: 100%;
    /* Ensure both the map and the form take up the same full width */
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.modal-content h2 {
  font-size: 20px;
  margin-bottom: 20px;
}

.modal-content button {
  background-color: #f04a4a;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
