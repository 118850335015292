body {
  margin: 0;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.jeju-font {
  font-family: 'Jeju Myeongjo', serif;
  margin: 0;
  font-weight: lighter;
}

.jockey-font {
  font-family: 'Jockey One', sans-serif;
  margin: 0;
}

.primaryRedText {
  color: #DA1820
}

.primaryRedBg {
  background-color: #DA1820
}

/* For bigger screens */
.paddingH {
  padding-left: 300px;
  padding-right: 300px;
}

.paddingV {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media (max-width: 1540px) {
  .paddingH {
    padding-left: 200px;
    padding-right: 200px;
  }

  .paddingV {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

/* For medium screens */
@media (max-width: 1024px) {
  .paddingH {
    padding-left: 150px;
    padding-right: 150px;
  }

  .paddingV {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

/* For smaller screens */
@media (max-width: 768px) {
  .paddingH {
    padding-left: 100px;
    padding-right: 100px;
  }

  .paddingV {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

/* For extra small screens */
@media (max-width: 450px) {
  .paddingH {
    padding-left: 20px;
    padding-right: 20px;
  }

  .paddingV {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}