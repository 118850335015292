.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #4b4b4b;
    padding-top: 35px;
    padding-bottom: 35px;
  }
  
  .footer-copyright {
    color: white;
    font-size: 14px;
  }
  
  .footer-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45.08px;
    width: 125.25px;
  }
  
  .footer-icons {
    display: flex;
    gap: 6px;
  }
  
  .footer-icon-link {
    display: flex;
    align-items: center;
  }
  
  /* Small screens */
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
      gap: 20px; /* Add space between items for better spacing on small screens */
    }
    
    .footer-icons {
      justify-content: center;
    }
  }
  