.gallery-container {
  height: 100svh; /* Full height of the screen */
  max-height: 744px;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.gallery-header {
  padding-left: 80px;
}

.gallery-content {
  display: flex;
  justify-content: space-between;
}

.gallery-description {
  flex: 1;
  display: flex;
  width: 50%;
  align-items: end;
  margin-right: 70px;
  margin-bottom: 10%;
}

.gallery-images {
  flex: 1;
  width: 50%;
  position: relative;
}

.gallery-image-row {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  position: relative;
}

.image-red {
  width: 290px; /* Reduced by 10px */
  height: 470px; /* Reduced by 10px */
  background-image: url('../../../assets/Images/gallery/1.jpg');
  background-size: cover;
  background-position: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.image-blue {
  width: 190px; /* Reduced by 10px */
  height: 390px; /* Reduced by 10px */
  margin: 0 10px;
  background-image: url('../../../assets/Images/gallery/3.jpg');
  background-size: cover;
  background-position: center;
  opacity: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.image-pink {
  width: 160px; /* Reduced by 10px */
  height: 310px; /* Reduced by 10px */
  background-image: url('../../../assets/Images/gallery/4.jpg');
  background-size: cover;
  background-position: center;
  opacity: 30%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.next-button {
  position: absolute;
  background-color: transparent;
  border: 0;
  display: flex;
  width: 100px; /* Increased width for a larger button */
  height: 100px; /* Increased height for a larger button */
  padding: 0;
  right: -40px;
  transition: transform 0.3s ease-in-out; /* Add transition for smooth transform */
}

.next-button:hover {
  transform: scale(1.1);
}

.gallery-bg-icon {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
}

/* Styles for smaller screens */
@media screen and (max-width: 450px) {
  .gallery-container {
    height: fit-content; /* Full height of the screen for small screens as well */
  }

  .gallery-header {
    padding-top: 40px;
    padding-left: 20px;
    margin-bottom: 40px; /* Increased gap between header and content */
  }

  .gallery-description {
    display: none;
  }

  .gallery-images {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 150px;
  }

  .gallery-image-row {
    flex-direction: row;
    justify-content: center;
  }

  .image-red {
    width: 130px; /* Reduced by 10px */
    height: 210px; /* Reduced by 10px */
  }

  .image-blue {
    width: 90px; /* Reduced by 10px */
    height: 170px; /* Reduced by 10px */
    margin: 0 5px;
  }

  .image-pink {
    width: 70px; /* Reduced by 10px */
    height: 130px; /* Reduced by 10px */
  }

  .next-button {
    width: 40px; /* Adjusted for smaller button */
    height: 40px;
    right: -20px;
  }

  .gallery-bg-icon {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

/* Adjusting sizes based on screen width */
@media screen and (min-width: 600px) {
  .gallery-container {
    height: fit-content; /* Full height of the screen for small screens as well */
    padding-top: 40px;
  }

  .image-red {
    width: 190px; /* Reduced by 10px */
    height: 310px; /* Reduced by 10px */
  }

  .image-blue {
    width: 140px; /* Reduced by 10px */
    height: 230px; /* Reduced by 10px */
  }

  .image-pink {
    width: 110px; /* Reduced by 10px */
    height: 170px; /* Reduced by 10px */
  }

  .gallery-description {
    display: none;
  }

  .gallery-image-row {
    padding-top: 40px;
    padding-bottom: 140px;
  }
}

@media screen and (min-width: 900px) {
  .image-red {
    width: 290px; /* Reduced by 10px */
    height: 470px; /* Reduced by 10px */
  }

  .image-blue {
    width: 190px; /* Reduced by 10px */
    height: 390px; /* Reduced by 10px */
  }

  .image-pink {
    width: 160px; /* Reduced by 10px */
    height: 310px; /* Reduced by 10px */
  }
}

@media screen and (min-width: 1200px) {
  .gallery-container {
    height: 100svh;
  }

  .image-red {
    width: 390px; /* Reduced by 10px */
    height: 400px; /* Reduced by 10px */
  }

  .image-blue {
    width: 240px; /* Reduced by 10px */
    height: 330px; /* Reduced by 10px */
  }

  .image-pink {
    width: 190px; /* Reduced by 10px */
    height: 230px; /* Reduced by 10px */
  }

  .gallery-description {
    flex: 1;
    display: flex;
    width: 50%;
    align-items: end;
    margin-right: 70px;
    margin-bottom: 15%;
  }
}
