.operate-container {
  display: flex;
  flex-direction: column;
  background-color: #da1820;
  background-image: url("../../../assets/Icons/WhereWeOperateBig.svg");
  background-size: cover; /* or 'contain', depending on your needs */
  background-position: left;
  background-repeat: no-repeat; /* to avoid tiling */
}

.operate-header {
  padding-left: 80px;
}

.operate-font {
  display: flex;
  justify-content: flex-end;
  font-size: 20px;
  color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}

.operate-flex {
  display: flex;
}

.operate-flex-center {
  display: flex;
  align-items: center;
}

.operate-text-container {
  display: flex;
  width: 50%;
  padding-right: 200px;
  padding-left: 200px;
  flex-direction: column;
}

.gallery-links {
  display: flex;
  width: "100%";
  justify-content: center;
  gap: 6px;
}

/* Small screens */
@media (min-width: 200px) {
  .operate-container {
    background-image: url("../../../assets/Icons/WhereWeOperateSmall.svg");
  }

  .operate-header {
    padding-left: 20px;
  }

  .operate-font {
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: center;
  }

  .operate-text-container {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 170px;
  }

  .gallery-links {
    display: flex;
    flex-direction: column;
    width: "100%";
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
    margin-bottom: 60px;
  }
}

@media (min-width: 500px) {
  .operate-container {
    background-image: url("../../../assets/Icons/WhereWeOperateMedium.svg");
    background-position: left;
  }

  .operate-font {
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: center;
  }

  .operate-text-container {
    width: 100%;
    padding-right: 40px;
    padding-left: 40px;
    text-align: center;
  }

  .gallery-links {
    display: flex;
    flex-direction: column;
    width: "100%";
    align-items: flex-end;
    gap: 6px;
  }

  .wwo-hidden {
    display: none;
  }
}

/* Medium screens */
@media (min-width: 800px) {
  .operate-container {
    background-image: url("../../../assets/Icons/WhereWeOperateMedium.svg");
    background-position: center;
  }

  .operate-header {
    padding-left: 40px;
  }

  .operate-font {
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: center;
  }

  .operate-text-container {
    width: 100%;
    padding-right: 100px;
    padding-left: 200px;
    text-align: center;
    padding-bottom: 0;
  }

  .gallery-links {
    display: flex;
    flex-direction: column;
    width: "100%";
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
  }

  .wwo-hidden {
    display: none;
  }
}

/* Large screens */
@media (min-width: 1024px) {
  .operate-container {
    background-image: url("../../../assets/Icons/WhereWeOperateBig.svg");
    background-position: center;
  }

  .operate-header {
    padding-left: 80px;
  }

  .operate-font {
    font-size: 20px;
    justify-content: end;
  }

  .operate-text-container {
    width: 50%;
    padding-right: 200px;
    text-align: left;
  }
  .gallery-links {
    display: flex;
    flex-direction: row;
    width: "100%";
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
  }

  .wwo-hidden {
    display: block;
  }
}
