/* Base styles for larger screens */
.services-header {
  padding-left: 80px;
  padding-bottom: 40px;
}

.services-button-div {
  display: flex;
  justify-content: flex-end;
}

.services-row {
  display: grid;
  grid-template-columns: 1fr 5fr;
}

.services-lightG {
  background-color: lightgray;
  overflow-x: hidden
}

.services-m0 {
  margin: 0px
}

.services-grid {
  display: grid;
  grid-column: auto;
  font-size: 20px;
  gap: 20px;
  /* Gap between rows for better spacing */
}

.service-columns {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1.5fr;
  gap: 50px;
  /* Keep this gap for larger screens */
}

/* Medium screens between 768px and 1200px */
@media (min-width: 768px) and (max-width: 1200px) {
  .services-header {
    padding-left: 40px;
    padding-bottom: 30px;
  }

  .service-columns {
    display: block;
    /* WallDetails will be alone on top */
  }

  /* Center WallDetails */
  .service-columns>div:first-child {
    display: flex;
    justify-content: center;
    /* Horizontally center WallDetails */
    align-items: center;
    /* Vertically center WallDetails (if needed) */
    margin-bottom: 40px;
    /* Add some space below WallDetails */
  }

  /* New grid structure for services */
  .services-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* Two services per row */
    gap: 40px;
    /* Gap between the services */
    margin-bottom: 40px;
    /* Add a gap at the bottom of the services */
  }

  .services-row {
    grid-template-columns: 1fr 4fr;
    /* Adjusted columns for better layout */
    gap: 6px;
    /* Gap between icon and text */
    font-size: 18px;
    /* Set font size to 18px */
  }

  .services-button-div {
    justify-content: center;
    /* Center the button on medium screens */
  }
}

/* Small screens (max-width: 768px) */
@media (max-width: 768px) {
  .services-header {
    padding-left: 20px;
    padding-bottom: 20px;
  }

  .services-row {
    grid-template-columns: 1fr;
    /* Single column layout for rows */
  }

  .service-columns {
    grid-template-columns: 1fr;
    /* Single column layout */
    gap: 20px;
  }

  .services-button-div {
    justify-content: center;
    /* Center the button on small screens */
    padding-top: 20px;
    /* Add padding to separate the button from content */
  }

  /* Center WallDetails on small screens */
  .service-columns>div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    /* Add some space below the WallDetails component */
  }
}

/* Slide in from right animation */
@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Apply the animation */
.services-row {
  opacity: 0;
  transform: translateX(100%);
  transition: transform 1s ease-out, opacity 1s ease-out;
}

/* Class to trigger the animation */
.services-row.visible {
  opacity: 1;
  transform: translateX(0);
}

.wall-image-container {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wall-image {
  width: 100%;
  height: auto;
  max-width: 500px;
  object-fit: contain;
  transition: opacity 1s ease-in-out;
  /* Smooth opacity transition */
}

.wall-image.fade-in {
  opacity: 1;
  /* Fully visible */
}

.wall-image.fade-out {
  opacity: 0;
  /* Fades out */
}