/* Base styles for larger screens */
.error-screen-container {
  background-color: #DA1820;
  align-items: center;
  height: 100svh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.error-logo img {
  width: '100%';
  height: 45px;
}

.inner-box h1 {
  margin: 0px;
}

.inner-box h3 {
  margin: 0px;
  font-size: 20px;
}

/* Inner box with full opacity */
.inner-box {
  background-color: white;
  opacity: 1; /* Full opacity */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  padding: 20px; /* Add some spacing inside */
  border-radius: 8px; /* Optional: rounded corners */
  text-align: center; /* Center the text */
  font-size: 24px; /* Adjust font size */
  font-weight: bold;
  height: 45svh;
  font-family: 'Jockey One', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.button-container-error {
  max-width: fit-content;
  min-width: 110px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 15px;
  padding-left: 15px;
  border: 0;
  font-size: 18px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  align-self: center;
}

.button-container-error:hover {
  transform: scale(1.1);
}

