.about-container {
  background-image: url('../../../assets/Images/about-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
}

.about-header {
  padding-left: 80px;
}

.about-description {
  display: flex;
  flex-direction: column;
  color: rgb(255, 255, 255);
  gap: 16px;
  font-size: 18px;
  max-width: 60%;
  padding-bottom: 70px;
}

/* Small screens */
@media (max-width: 450px) {
  .about-container {
    background-image: url('../../../assets/Images/about-bg-small.png');
    background-position: bottom; /* Align background image to the bottom */
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .about-header {
    padding-left: 20px;
  }

  .about-description {
    max-width: 100%; /* Make the description full width */
    font-size: 16px; /* Adjust font size for smaller screens */
  }
}

/* Medium screens */
@media (min-width: 451px) and (max-width: 1024px) {
  .about-container {
    background-image: url('../../../assets/Images/about-bg-small.png');
    background-position: bottom; /* Align background image to the bottom */
    padding: 30px 15px;
  }

  .about-header {
    padding-left: 40px;
  }

  .about-description {
    max-width: 80%; /* Adjust width for medium screens */
    font-size: 17px; /* Adjust font size for medium screens */
  }
}
