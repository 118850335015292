.button-container {
    max-width: fit-content;
    min-width: 110px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 15px;
    padding-left: 15px;
    border: 0;
    font-size: 18px;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.button-container:hover {
    transform: scale(1.1);
}
