.about-screen-1 {
  background-image: url('../../assets/Images/about-screen-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 60px;
}

.about-screen-partners-info h1{
  line-height: 1;
  margin-bottom: 20px;
}

.about-screen-1 p, .about-screen-2 p {
  margin: 0;
}

.about-gray-color {
  color: #A4A4A4;
}

.about-red-color {
  color: #DA1820;
}

.about-screen-metrics {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
}

.about-screen-metric-block {
  text-align: right;
  width: 150px;
}

.about-screen-metric-block h1 {
  margin: 0;
  font-size: 80px;
  line-height: 1;
}

.about-screen-metric-block h3 {
  margin: 0;
  font-size: 30px;
  line-height: 1;
}

.about-screen-red-text {
  color: #da1820;
}

.about-screen-grey-text {
  color: #a4a4a4;
}

.about-screen-description {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 20px;
}

.about-screen-description h1 {
  color: #da1820;
}

.about-screen-partners-info {
  font-size: 20px;
}

.about-screen-description {
  font-size: 20px;
}

.about-screen-info, .about-screen-why-choose-us {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.about-screen-2 {
  background-color: #b8b8b8;
  display: flex;
  gap: 90px;
}

.about-screen-metrics-hidden {
  display: none;
}

/* Mobile Devices – 320px — 480px */
@media (max-width: 480px) {
  .about-screen-1 {
    flex-direction: column;
    padding: 20px;
    gap: 30px;
    height: auto;
  }

  .about-screen-description {
    gap: 10px;
  }

  .about-screen-info + .about-screen-metrics {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
  }

  .about-screen-metrics {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .about-screen-metric-block {
    width: 45%;
    text-align: center;
  }

  .about-screen-metric-block h1 {
    font-size: 40px;
  }

  .about-screen-metric-block h3 {
    font-size: 18px;
  }

  .about-screen-why-choose-us {
    flex-direction: column;
    gap: 20px;
  }

  .about-screen-2 {
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    
  }

 

  .about-screen-partners-info h1{
    line-height: 1;
  }

}

/* Tablets – 481px — 768px */
@media (min-width: 481px) and (max-width: 768px) {
  .about-screen-1 {
    flex-direction: column;
    padding: 30px;
    gap: 30px;
    height: auto;
  }

  .about-screen-info + .about-screen-metrics {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
  }

  .about-screen-metric-block {
    width: 45%;
    text-align: center;
  }

  .about-screen-metric-block h1 {
    font-size: 50px;
  }

  .about-screen-metric-block h3 {
    font-size: 20px;
  }

  .about-screen-partners-info {
    font-size: 18px;
  }

  .about-screen-description p {
    font-size: 18px;
  }

  .about-screen-2 {
    flex-direction: column;
    padding: 30px;
  }

}

/* Laptops and Small Screens – 769px — 1024px */
@media (min-width: 769px) and (max-width: 1024px) {
  .about-screen-1 {
    flex-direction: row;
    padding: 40px;
    gap: 40px;
    height: auto;
  }

  .about-screen-info + .about-screen-metrics {
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
  }

  .about-screen-metric-block {
    text-align: center;
    width: 140px;
  }

  .about-screen-metric-block h1 {
    font-size: 60px;
  }

  .about-screen-metric-block h3 {
    font-size: 24px;
  }

  .about-screen-partners-info {
    font-size: 20px;
  }

  .about-screen-description p {
    font-size: 20px;
  }

  .about-screen-2 {
    flex-direction: row;
    padding: 40px;
  }

}

/* Large Screens and Desktops – 1025px — 1200px */
@media (min-width: 1025px) and (max-width: 1200px) {
  .about-screen-1 {
    flex-direction: row;
    gap: 50px;
    padding: 50px;
  }

  .about-screen-metrics {
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
  }

  .about-screen-metric-block {
    text-align: right;
    width: 150px;
  }

  .about-screen-metric-block h1 {
    font-size: 70px;
  }

  .about-screen-metric-block h3 {
    font-size: 28px;
  }

  .about-screen-description {
    width: 50%;
    text-align: left;
  }

  .about-screen-2 {
    flex-direction: row;
    /* padding: 50px; */
  }
}

/* Extra Large Screens – 1201px and above */
@media (min-width: 1201px) {
  .about-screen-1 {
    flex-direction: row;
    gap: 60px;
  }

  .about-screen-metrics {
    display: none;
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;
  }

  .about-screen-metrics-hidden {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
  }

  .about-screen-metric-block {
    text-align: right;
    width: 150px;
  }

  .about-screen-metric-block h1 {
    font-size: 80px;
  }

  .about-screen-metric-block h3 {
    font-size: 30px;
  }

  .about-screen-description {
    text-align: left;
  }

  .about-screen-2 {
    flex-direction: row;
  }

}